.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.file-manager-modal {
  margin-top: 15vh !important;
  min-width: 75%;
}

/* Small Devices, Tablets */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .file-manager-modal-body {
    max-height: 71vh;
    min-height: 35vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

/* Large monitor */

@media only screen and (min-width: 1900px) {
  .file-manager-modal-body {
    max-height: 67vh;
    min-height: 33vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

/* Medium laptop */

@media only screen and (min-width: 992px) and (max-width: 1499px) {
  .file-manager-modal-body {
    max-height: 51.5vh;
    min-height: 25vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

/* Small laptop */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .file-manager-modal-body {
    max-height: 44vh;
    min-height: 22vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

/* Something between */

@media only screen and (min-width: 1500px) and (max-width: 1899px) {
  .file-manager-modal-body {
    max-height: 59vh;
    min-height: 29vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.btn-success {
  background-color: #67c23a !important;
  border-color: #67c23a !important;
}

.btn.focus, .btn:focus {
  box-shadow: none !important;
}

.btn:active {
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25) !important;
}

.no-border {
  border-color: transparent !important;
}

.react-switch {
  vertical-align: text-bottom;
}
